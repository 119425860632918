














































































import {useUiHelpers} from '~/composables'
import DsbSelectProfileModal from "~/modules/web2print/components/DsbSelectProfileModal.vue";
import SearchListItem from '~/modules/catalog/search/components/SearchListItem.vue'
import {SfLoader} from '@storefront-ui/vue'
import {
  computed,
  useRoute,
  defineComponent, onMounted, PropType, ref
} from '@nuxtjs/composition-api';
import SearchBar from "~/modules/catalog/search/components/SearchBar.vue";
import SearchCategorySidebar from "~/modules/catalog/search/components/SearchCategorySidebar.vue";
import DsbProductListHeader from "~/components/ProductList/DsbProductListHeader.vue";
import DsbProductList from "~/components/ProductList/DsbProductList.vue";
import {SortingModel} from '~/modules/catalog/category/composables/useFacet/sortingOptions';
import PageHeader from "~/components/Page/PageHeader.vue";
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import { SearchBarId } from "~/modules/catalog/search/enum";

export default defineComponent({
  name: 'SearchPage',
  middleware: ['is-authenticated', 'has-accepted-licenses', 'has-dummy-email', 'is-internal-valid'],
  components: {
    PageHeader,
    SearchBar,
    SearchListItem,
    SfLoader,
    DsbProductListHeader,
    SearchCategorySidebar,
    DsbProductList,
    DsbSelectProfileModal,
    SkeletonLoader
  },
  props: {
    view: {
      type: String,
      required: true
    },
    products: {
      type: Array,
      required: true
    },
    sortBy: {
      type: Object as PropType<SortingModel>
    },
    pagination: {
      required: true,
      type: Object,
    },
    productsLoading: {
      type: Boolean,
      required: true
    },
    categories: {
      type: Object,
      required: true
    },
    pageTitle: {
      type: String,
      required: true
    },
    searchString: {
      type: [String, Array]
    },
    showSearchBar: {
      type: Boolean,
      default: true
    }
  },
  // @ts-ignore
  head() {
  },
  setup() {
    const { itemsPerPage } = useUiHelpers().getFacetsFromURL()

    const isSelectProfileModalVisible = ref(false);
    const selectedProduct = ref({});

    function openPersonalisation(productOptions) {
      selectedProduct.value = productOptions;
      isSelectProfileModalVisible.value = true;
    }

    onMounted(() => {
      const route = useRoute();
      window?.localStorage.setItem('fromSearchResults', route.value.fullPath);
    })

    return {
      itemsPerPage,
      SearchBarId,
      openPersonalisation,
      selectedProduct,
      isSelectProfileModalVisible
    }
  },
});
