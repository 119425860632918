












import {usePage} from '~/composables'
import {ListView} from '~/types/ListView'
import {useSearchList} from '~/modules/catalog/search/composables/useSearch/searchList'
import {PageCategory} from "~/types/PageCategory";
import {General} from "~/types/Page";
import {
  defineComponent, useFetch, computed, useRoute, useMeta
} from '@nuxtjs/composition-api';
import SearchPage from "~/modules/catalog/search/components/SearchPage.vue";
import {useI18n} from "~/helpers/hooks/usei18n";

export default defineComponent({
  name: 'Search',
  middleware: ['is-authenticated', 'has-accepted-licenses', 'has-dummy-email', 'is-internal-valid'],
  components: {
    SearchPage
  },
  // @ts-ignore
  head() {},
  setup() {
    const i18n = useI18n();
    const {pageTitle} = usePage();
    const {title} = useMeta()
    const route = useRoute();

    pageTitle.value = i18n.t(`${PageCategory.SEARCH}.${General.TITLE}`).toString()
    title.value = pageTitle.value;

    const {
      products,
      fetchSearchResults,
      productsLoading,
      sortBy,
      pagination,
      categoryList
    } = useSearchList()

    const searchString= route.value?.query?.term ?? '';

    useFetch(async () => {
      await fetchSearchResults();
    })

    return {
      view: computed(() => ListView.SEARCH),
      products: computed(() => products.value),
      sortBy: computed(() => sortBy.value),
      pagination: computed(() => pagination.value),
      productsLoading: computed(() => productsLoading.value),
      categories: computed(() => categoryList.value),
      searchString,
      pageTitle
    }
  },
});
