import {computed, ref, ssrRef, useRoute, useRouter} from '@nuxtjs/composition-api'
import {ActionType} from '~/types/Action'
import {useFacet, useProductList, useUiHelpers} from '~/composables'
import {ProductDetailType} from '~/types/ProductData'
import {customComputedRef} from "~/helpers/customComputedRef";
import facetGetters from "~/modules/catalog/category/getters/facetGetters";
import productGetters from "~/modules/catalog/product/getters/productGetters";

const {
  products,
  sidebar,
  sortBy,
  facets,
  pagination,
  activeCategory,
  sidebarLoading,
  showAllAction
} = useProductList()
const isInitialized = ref(false)
const rawProducts = customComputedRef(computed<any[]>(() => []))

const attributes = ['delivery_type', 'stock_status', 'print_on_demand']

const actions = [
  ActionType.DOWNLOAD,
  ActionType.ADD_TO_CART,
  ActionType.DETAILS
]

const details: ProductDetailType[] = [
  ProductDetailType.ARTICLE_NUMBER,
  ProductDetailType.VERSION_PRINT,
  ProductDetailType.VERSION_DOWNLOAD,
  ProductDetailType.PRICE
]

export function useSearchList() {
  const uiHelpers = useUiHelpers();
  const route = useRoute();
  const router = useRouter();
  const isSearchPage = true;

  const {
    result,
    search,
    loading: productsLoading
  } = useFacet(isSearchPage);

  const categoryList = ssrRef<any>({})

  const fetchRecentlyUpdated = async (numberOfProducts: number = 12) => {
    let today = new Date();
    const priorDate = new Date(today.setDate(today.getDate() - 30))
    const date = priorDate.toISOString().replace(/T\d+:\d+:.*Z/g, '')

    const searchQuery = {...uiHelpers.getFacetsFromURL()}

    const baseSearchQuery = {
      filters: {
        version_updated_at: {
          from: date,
          to: "",
        },
      },
      sort: 'version_updated_at_DESC',
      itemsPerPage: searchQuery.itemsPerPage ?? numberOfProducts,
      page: searchQuery.page ?? 1,
    };

    await fetchSearchResults(baseSearchQuery);

    return products.value
  }

  const fetchSearchResults = async (searchQuery?: {
    filters, sort: string, itemsPerPage?: number, page?: number
  }) => {

    if (!searchQuery) {
      searchQuery = {...uiHelpers.getFacetsFromURL()}
    }

    if (searchQuery) {
      const query = uiHelpers.resolveQuery()
      searchQuery.filters.category_uid = query.category_uid ?? ''
    }

    await search(searchQuery);

    // @ts-ignore
    products.value = facetGetters.getProducts(result.value).map((product) => ({
      ...productGetters.getProductData(product, details, actions)
    }));

    sortBy.value = facetGetters.getSortOptions(result.value);
    pagination.value = facetGetters.getPagination(result.value);

    categoryList.value = computed(() => {
      const categoryTree = result.value.data?.aggregations.filter((aggregation) => {
        if (aggregation.attribute_code === 'category_uid') {
          return true;
        }
      })

      // @ts-ignore
      return categoryTree[0]?.options
    })
  }

  showAllAction.value = async () => {
    router.replace({
      ...route,
      query: {term: route.value.query.term}
    })
  }

  return {
    ...useProductList(),
    rawProducts,
    actions,
    isInitialized,
    products,
    productsLoading,
    sidebar,
    sidebarLoading,
    sortBy,
    facets,
    pagination,
    activeCategory,
    result,
    fetchSearchResults,
    fetchRecentlyUpdated,
    categoryList
  }
}
